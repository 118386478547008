// 引入 axios
import axios from "axios";

let base = "http://xhy-seals.com:8888";
// let base = "http://localhost:8888"
//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}
